import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';

@Component({
  selector: 'mp-filter-input-toggle',
  standalone: true,
  templateUrl: './filter-input-toggle.component.html',
  styleUrl: './filter-input-toggle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatCardModule, MatIconModule, MatSlideToggleModule],
})
export class FilterInputToggleComponent {
  @HostBinding('class.checked')
  @Input()
  checked = false;

  @Input() disabled = false;
  @Input() title?: string;
  @Input() icon?: string;
  @Input() toggleLabels: [string | undefined, string | undefined] = [undefined, undefined];

  @Output() readonly valueChanges = new EventEmitter<boolean>();

  emitValueChanges(value: boolean): void {
    if (!this.disabled) {
      this.valueChanges.emit(value);
      this.checked = value;
    }
  }
}
