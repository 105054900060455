import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { ChipProperties } from '../chip-properties';

@Component({
  selector: 'mp-custom-chip',
  standalone: true,
  templateUrl: './custom-chip.component.html',
  styleUrl: './custom-chip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatLegacyButtonModule],
})
export class CustomChipComponent {
  readonly useSmallChips: InputSignal<boolean> = input<boolean>(false);
  readonly chip: InputSignal<ChipProperties> = input.required<ChipProperties>();

  readonly remove: OutputEmitterRef<string | undefined> = output<string | undefined>();

  removeChip(value?: string): void {
    this.remove.emit(value);
  }
}
