<button
  *ngIf="!isProcessing; else isProcessingTemplate"
  class="export-button"
  mat-raised-button
  [disabled]="disabled"
  [matTooltip]="disabled ? disabledStateTooltipText : ''"
  (click)="emitButtonClicked()"
>
  <mat-icon>login</mat-icon>
  Export
</button>

<ng-template #isProcessingTemplate>
  <button mat-flat-button class="export-in-progress-button" color="accent" [disabled]="true" title="Wird verarbeitet...">
    <mat-progress-spinner mode="indeterminate" diameter="20"> </mat-progress-spinner>
  </button>
</ng-template>
