import { OverlayModule } from '@angular/cdk/overlay';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  OutputEmitterRef,
  Signal,
  ViewChild,
  WritableSignal,
  output,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import {
  atLeastOneDigit,
  atLeastOneLowercaseLetter,
  atLeastOneNonAlphanumericCharacter,
  atLeastOneUppercaseLetter,
  atLeastSixCharacters,
  passwordsMatchValidator,
} from './password-validators';

type Requirement = { isFulfilled: boolean; label: string };

@Component({
  selector: 'mp-change-password-input',
  standalone: true,
  templateUrl: './change-password-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    ReactiveFormsModule,

    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyFormFieldModule,
    MatLegacyOptionModule,
    MatLegacyInputModule,
    OverlayModule,
  ],
})
export class ChangePasswordInputComponent {
  @HostBinding() readonly class = 'mp-change-password-input';

  @ViewChild('passwordInput', { static: true, read: ElementRef<HTMLInputElement> })
  passwordInputRef!: ElementRef<HTMLInputElement>;

  readonly changePassword: OutputEmitterRef<string> = output<string>();

  protected readonly form: UntypedFormGroup = this.buildForm();

  protected readonly passwordRequirements: Signal<Requirement[]> = toSignal(this.buildPasswordRequirements$(), {
    requireSync: true,
  });

  protected readonly isRequirementsOverlayOpen: WritableSignal<boolean> = signal<boolean>(false);

  private buildPasswordRequirements$(): Observable<Requirement[]> {
    return this.form.valueChanges.pipe(
      startWith(() => this.buildPasswordRequirements()),
      map(() => this.buildPasswordRequirements()),
      map((requirements: Requirement[]) => (this.isEveryRequirementFulfilled(requirements) ? [] : requirements)),
    );
  }

  private buildPasswordRequirements(): Requirement[] {
    return [
      {
        isFulfilled: !this.form.getError('atLeastSixCharacters', 'password'),
        label: 'Mindestens 6 Zeichen',
      },
      {
        isFulfilled: !this.form.getError('atLeastOneLowercaseLetter', 'password'),
        label: 'Mindestens ein Kleinbuchstabe',
      },
      {
        isFulfilled: !this.form.getError('atLeastOneUppercaseLetter', 'password'),
        label: 'Mindestens ein Großbuchstabe',
      },
      {
        isFulfilled: !this.form.getError('atLeastOneDigit', 'password'),
        label: 'Mindestens eine Zahl',
      },
      {
        isFulfilled: !this.form.getError('atLeastOneNonAlphanumericCharacter', 'password'),
        label: 'Mindestens ein Sonderzeichen',
      },
    ];
  }

  private isEveryRequirementFulfilled(requirements: Requirement[]): boolean {
    return requirements.every(({ isFulfilled }) => isFulfilled);
  }

  private buildForm(): UntypedFormGroup {
    const passwordValidators = [
      atLeastSixCharacters,
      atLeastOneDigit,
      atLeastOneLowercaseLetter,
      atLeastOneUppercaseLetter,
      atLeastOneNonAlphanumericCharacter,
    ];

    return new UntypedFormGroup(
      {
        password: new UntypedFormControl('', passwordValidators),
        passwordConfirmation: new UntypedFormControl(''),
      },
      passwordsMatchValidator,
    );
  }

  onButtonClick(): void {
    const password = this.form.get('password')?.value;
    this.changePassword.emit(password);
  }
}
