import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { RouterLink } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { Observable } from 'rxjs';

import {
  ActiveModuleNameService,
  FeatureFlags,
  HeaderContentService,
  HeaderElementPlacement,
  HeaderElementsMap,
  ThemeAssetsPathPipe,
} from '@core/shared/util';

@Component({
  selector: 'mp-app-header',
  standalone: true,
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    NgClass,
    NgTemplateOutlet,
    RouterLink,
    LetDirective,

    MatButtonModule,
    MatIconModule,

    ThemeAssetsPathPipe,
  ],
})
export class AppHeaderComponent {
  readonly featureFlags: InputSignal<FeatureFlags> = input.required<FeatureFlags>();
  readonly showAppLogo: InputSignal<boolean> = input.required<boolean>();
  readonly appTitle: InputSignal<string> = input.required<string>();

  readonly sidenavToggle: OutputEmitterRef<void> = output<void>();

  readonly headerElementsMap$: Observable<HeaderElementsMap> = this.headerContentService.headerElementsMap$;

  readonly activeModuleName$: Observable<string> = this.activeModuleNameService.activeModuleName$;

  readonly headerElementPlacements: HeaderElementPlacement[] = ['left', 'center', 'right'];

  constructor(
    private readonly headerContentService: HeaderContentService,
    private readonly activeModuleNameService: ActiveModuleNameService,
  ) {}

  toggleSidenav(): void {
    this.sidenavToggle.emit();
  }
}
