import { AbstractControl, ValidatorFn } from '@angular/forms';

export const passwordsMatchValidator: ValidatorFn = (group: AbstractControl) => {
  const password: string = group.get('password')?.value;
  const passwordConfirmed = group.get('passwordConfirmation')?.value;

  return password === passwordConfirmed ? null : { passwordsDontMatch: true };
};

export const atLeastSixCharacters: ValidatorFn = (control: AbstractControl) => {
  const password: string = control.value;
  const hasSixCharacters = password.length >= 6;

  return hasSixCharacters ? null : { atLeastSixCharacters: true };
};

export const atLeastOneLowercaseLetter: ValidatorFn = (control: AbstractControl) => {
  const password: string = control.value;
  const hasLowercaseLetter = password.match('[a-z]') !== null;

  return hasLowercaseLetter ? null : { atLeastOneLowercaseLetter: true };
};

export const atLeastOneUppercaseLetter: ValidatorFn = (control: AbstractControl) => {
  const password: string = control.value;
  const hasUppercaseLetter = password.match('[A-Z]') !== null;

  return hasUppercaseLetter ? null : { atLeastOneUppercaseLetter: true };
};

export const atLeastOneNonAlphanumericCharacter: ValidatorFn = (control: AbstractControl) => {
  const password: string = control.value;

  const hasNonAlphanumericCharacter = password.match('[^a-zA-Z0-9\\s:]') !== null;

  return hasNonAlphanumericCharacter ? null : { atLeastOneNonAlphanumericCharacter: true };
};

export const atLeastOneDigit: ValidatorFn = (control: AbstractControl) => {
  const password: string = control.value;
  const hasDigit = password.match('[0-9]') !== null;

  return hasDigit ? null : { atLeastOneDigit: true };
};
