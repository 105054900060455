import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@Component({
  selector: 'mp-input-form',
  standalone: true,
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, MatButtonModule, MatInputModule, MatTooltipModule],
})
export class InputFormComponent {
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() disabledStateTooltipText = '';
  @Input() inputValue = '';
  @Output() readonly updateValue = new EventEmitter<string>();

  emitSave(): void {
    this.updateValue.emit(this.inputValue);
  }
}
