<span class="chip truncate" [class]="{ 'chip--small': useSmallChips() }">
  @if (chip().name) {
    <span class="chip__name truncate" [class]="{ 'chip__name--small': useSmallChips() }" [title]="chip().name">{{
      chip().name
    }}</span>
  }
  <span class="chip__label truncate" [class]="{ 'chip__label--small': useSmallChips() }" [title]="chip().label">{{
    chip().label
  }}</span>

  <button
    mat-icon-button
    class="chip__button"
    [class]="{ 'chip__button--small': useSmallChips() }"
    (click)="removeChip(chip().value)"
  >
    <mat-icon class="chip__button-icon" [class]="{ 'chip__button-icon--small': useSmallChips() }">close</mat-icon>
  </button>
</span>
