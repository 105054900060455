import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@Component({
  selector: 'mp-export-button',
  standalone: true,
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MatButtonModule, MatIconModule, MatProgressSpinnerModule, MatTooltipModule],
})
export class ExportButtonComponent {
  @HostBinding() readonly class = 'mp-export-button';

  @Input() isProcessing = false;
  @Input() disabled = false;
  @Input() disabledStateTooltipText = '';

  @Output() readonly clicked = new EventEmitter<void>();

  emitButtonClicked(): void {
    this.clicked.emit();
  }
}
