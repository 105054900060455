<mat-card class="filter-input-card" (click)="emitValueChanges(!checked)">
  <div class="card-content">
    @if (icon) {
      <div class="filter-input-icon">
        <mat-icon class="material-icons-outlined">{{ icon }}</mat-icon>
      </div>
    }

    <div class="filter-input-title">
      <span>{{ title }}</span>
    </div>

    <div class="filter-input-toggle" (click)="$event.stopPropagation()">
      <div class="toggle">
        @if (toggleLabels[0]) {
          <div class="left-label">
            <span>{{ toggleLabels[0] }}</span>
          </div>
        }
        <mat-slide-toggle [checked]="checked" [disabled]="disabled" (change)="emitValueChanges($event.checked)" />
        @if (toggleLabels[1]) {
          <div class="right-label">
            <span>{{ toggleLabels[1] }}</span>
          </div>
        }
      </div>
    </div>
  </div>
</mat-card>
