import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { AvatarComponent } from '../avatar/avatar.component';

@Component({
  selector: 'mp-nav-profile',
  standalone: true,
  templateUrl: './navigation-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'mp-nav-profile',
    '[class.mp-nav-profile--small]': 'size() === "small"',
    '[class.mp-nav-profile--large]': 'size() === "large"',
  },
  imports: [AvatarComponent],
})
export class NavigationProfileComponent {
  readonly user: InputSignal<string | null | undefined> = input<string | null>();
  readonly company: InputSignal<string | null | undefined> = input<string | null>();
  readonly picture: InputSignal<string | null | undefined> = input<string | null>();

  readonly size: InputSignal<'small' | 'large'> = input<'small' | 'large'>('large');
}
