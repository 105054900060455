import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, HostBinding, InputSignal, OutputRef, input } from '@angular/core';
import { outputFromObservable } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { LetDirective } from '@ngrx/component';
import { Observable, Subject, of } from 'rxjs';
import { delay, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'mp-copy-to-clipboard-button',
  standalone: true,
  templateUrl: './copy-to-clipboard-button.component.html',
  styleUrl: './copy-to-clipboard-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LetDirective, MatButtonModule, MatIconModule, MatLegacyTooltipModule],
})
export class CopyToClipboardButtonComponent {
  @HostBinding('class') readonly class = 'mp-copy-to-clipboard-button';

  private readonly _textCopied$: Subject<void> = new Subject<void>();

  readonly text: InputSignal<string> = input.required<string>();

  readonly textCopy: OutputRef<void> = outputFromObservable(this._textCopied$.asObservable());

  protected readonly textCopied$: Observable<boolean> = this._textCopied$.asObservable().pipe(
    switchMap(() => of(false).pipe(delay(2000), startWith(true))),
    startWith(false),
  );

  constructor(private readonly clipboard: Clipboard) {}

  copyTextToClipboard(): void {
    if (this.isDisabled()) {
      return;
    }

    const isCopiedToClipboard = this.clipboard.copy(this.text());
    if (isCopiedToClipboard) {
      this._textCopied$.next();
    }
  }

  isDisabled(): boolean {
    return !this.text();
  }
}
