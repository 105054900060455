@if (groupName()) {
  <span class="chips-group-name" [class]="{ 'chips-group-name--small': useSmallChips() }">{{ groupName() }}</span>
}

@for (chip of chips() | slice: 0 : listLength(); track $index) {
  <mp-custom-chip
    [useSmallChips]="useSmallChips()"
    [chip]="chip"
    (remove)="removeChip({ groupName: groupName(), value: $event })"
  />
}

@if (chips().length > DISPLAY_NUMBER_OF_CHIPS) {
  <mp-expand-chip
    [isListExpanded]="isListExpanded()"
    [useSmallChips]="useSmallChips()"
    [numberOfHiddenElements]="chips().length - DISPLAY_NUMBER_OF_CHIPS"
    (expandList)="expandList()"
  />
}
